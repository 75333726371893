import React, { Component } from 'react';
import Helmet from 'react-helmet'



class SEO extends Component {
  render() {
    const { pagePath, pageTitle, keywords, pageDescription, image, imageHeight, imageWidth } = this.props;
    let metaKeywords = (keywords) ? keywords: "Attorney, Family Law, Real Estate, Bankruptcy Law, Business Litigation and General Practice";
    let title = `Kenneth E. Kaiser | Attorney at Law | ${pageTitle}`;
    let description = (pageDescription)? pageDescription : "Practice areas include Family Law, Real Estate, Bankruptcy, Business Litigation and General Practice.";
    let siteUrl  = "https://kenkaiserlaw.com";
    let imgWidth = imageWidth;
    let imgHeight = imageHeight;
    let urlNoProtocol  = siteUrl.replace(/^https?:\/\//i, "");
    let type = "website";
    let imageSecure = siteUrl + image;
    let imageInsecure = "http://" + urlNoProtocol + image;
    let pageUrl = siteUrl + pagePath;
   




    // Default Website Schema
    const schemaOrgJSONLD = [
      {
        '@context': 'http://schema.org',
        '@type': 'Organization',
        url: siteUrl,
        name:" Kenneth E. Kaiser | Attorney at Law",
        alternateName: title,
      },
    ];


  
    schemaOrgJSONLD.push({
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: pageUrl,
    name: title,
    })
    


    return (
      <Helmet>
        {/* General tags */}
        <title>{title}</title>
        <meta name="image" content={image} />
        <meta name="description" content={description} />
        <meta name="keywords" content={metaKeywords}/>
        {/* Schema.org tags */}
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>

        {/* OpenGraph tags */}
        <meta property="og:title" content={title} />
       
        <meta property="og:description" content={description} />
        <meta property="fb:app_id" content='390698138353116'/>
        <meta property="og:type" content={type} />
        <meta property="og:url" content={pageUrl} />
        <meta property="og:image:secure_url" content={imageSecure} />
        <meta property="og:image" content={imageInsecure} />
        <meta property="og:image:width" content={imgWidth} />
        <meta property="og:image:height" content={imgHeight} />
        <meta property="og:description" content={description} />

        {/* Twitter Card tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:creator"
          content=''
        />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:image" content={image} />
        <meta name="twitter:description" content={description} />
      </Helmet>
    )
  }
}


export default SEO
