import React from "react"
import { Link } from "gatsby"
import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';
import BusinessIcon from '@material-ui/icons/Business';
import PrintIcon from '@material-ui/icons/Print';


const styles = theme => ({
  root: {
     flexGrow: 1,
    },
    footerContainer: {
      width: "100%",
      height: "auto",
      display: "flex",
      direction: 'row',
      justifyContent: "center",
      alignItems: "center",
      paddingBottom: "50px",
      paddingTop: "50px",
      //background: "linear-gradient(to bottom, #fff, #4A6476)"
    },
    footerItem: {
      [theme.breakpoints.down('sm')]: {
        height: "125px",
      },
      '& a': {
        textDecoration: 'none',
        color: "#000"
      }
    },
    icon: {
    margin: theme.spacing.unit,
    fontSize: 32,
    },
});



class Footer extends React.Component {


  render(){
    const {classes } = this.props;

    return (
      <div className={classes.root}>
        <Grid className={classes.footerContainer} container >
          <Grid className={classes.footerItem} item container spacing={24} direction="column" alignItems="center" xs={12} md={3}>
            <Link to="/map"><BusinessIcon className={classes.icon} /></Link>
            <Link to="/map" align="center">
            <Typography>
            502 North Plum Grove Road
            </Typography>
            <Typography>
            Palatine, IL  60067
            </Typography>
            </Link>

          </Grid>
          <Grid className={classes.footerItem} item container spacing={24} direction="column" alignItems="center" xs={12} md={3}>
            <a href="tel:8479916675"><PhoneIcon className={classes.icon} /></a>
            <Typography>
            <a href="tel:8479916675">Phone: 847-991-6675</a>
            </Typography>
          </Grid>
          <Grid className={classes.footerItem} item container spacing={24} direction="column" alignItems="center" xs={12} md={3}>
            <PrintIcon className={classes.icon} />
            <Typography>
            Fax: 847-991-6476
            </Typography>
          </Grid>
          <Grid className={classes.footerItem} item container spacing={24} direction="column" alignItems="center" xs={12} md={3}>
            <a href="mailto:kkaiser264@aol.com">
            <EmailIcon className={classes.icon} />
            </a>
            <Typography>
              <a href="mailto:kkaiser264@aol.com">
              E-mail: kkaiser264@aol.com
              </a>
            </Typography>
            
          </Grid>
        </Grid>
      </div>
  );
  }

}


export default withStyles(styles)(Footer);
