import React from 'react';
import { Link } from "gatsby"
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';


const drawerWidth = 240;

const styles = theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  logo:{
    width: "100%",
    textAlign: "center",
    marginRight: "75px"
  },

  menuButton: {
    marginLeft: 12,
    marginRight: 20,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: "#4A6476"
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing.unit * 3,
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
  link: {
    textDecoration: "none",
    color: "#fff",
  },
  link2: {
    textDecoration: "none",
    color: "#fff",
    fontWeight: "bold"
  },
  active: {
    color: "#96D9F0 !important"
  },
});

class PersistentDrawerLeft extends React.Component {
  state = {
    open: false,
  };

  handleDrawerOpen = () => {
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { classes, theme } = this.props;
    const { open } = this.state;

    return (
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={classNames(classes.appBar, {
            [classes.appBarShift]: open,
          })}
        >
          <Toolbar disableGutters={!open}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
            <div className= {classes.logo}>
              <Link className={classes.link}  to="/">
              <Typography className={classes.title} variant="h6" color="inherit" noWrap>
                Kenneth E. Kaiser
              </Typography>
              <Typography className={classes.title} variant="subtitle2" color="inherit" noWrap>
                Attorney at Law
              </Typography>
              </Link>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div className={classes.drawerHeader}>
            <IconButton onClick={this.handleDrawerClose}>
              {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider />
          <List>
          <ListItem>
            <Typography className={classes.title} variant="h6" noWrap>
              <Link className={classes.link2} activeClassName={classes.active} to="/">Home</Link>
            </Typography>
          </ListItem>
            <ListItem>
              <Typography className={classes.title} variant="h6" noWrap>
                <Link className={classes.link2} activeClassName={classes.active} to="/about">About</Link>
              </Typography>
            </ListItem>
            <ListItem>
              <Typography className={classes.title} variant="h6" noWrap>
                <Link className={classes.link2} activeClassName={classes.active} to="/real-estate">Real Estate</Link>
              </Typography>
            </ListItem>
            <ListItem>
              <Typography className={classes.title} variant="h6" noWrap>
                <Link className={classes.link2} activeClassName={classes.active} to="/family-law">Family Law</Link>
              </Typography>
            </ListItem>
            <ListItem>
              <Typography className={classes.title} variant="h6" noWrap>
                <Link className={classes.link2} activeClassName={classes.active} to="/bankruptcy">Bankruptcy</Link>
              </Typography>
            </ListItem>
            <ListItem>
              <Typography className={classes.title} variant="h6" noWrap>
                <Link className={classes.link2} activeClassName={classes.active} to="/general-practice">General Practice</Link>
              </Typography>
            </ListItem>
            <ListItem>
              <Typography className={classes.title} variant="h6" noWrap>
                <Link className={classes.link2} activeClassName={classes.active} to="/contact">Contact</Link>
              </Typography>
            </ListItem>
          </List>
        </Drawer>
      </div>
    );
  }
}

PersistentDrawerLeft.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(PersistentDrawerLeft);
