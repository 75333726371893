import React from "react"
import { Link } from "gatsby"
import { AppBar, Hidden, Button, Toolbar, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

import Drawer from "./drawer";


const styles = theme => ({
  root: {
    width: '100%',
  },
  appBar: {
      background: "linear-gradient(to bottom, #4A6476, #1d282f)",
  },
  active: {
    color: "#96D9F0 !important"
  },
  link: {
    textDecoration: "none",
    color: "#fff",
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    textTransform: "capitalize",
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
  contact: {
    textTransform: "capitalize",
    fontSize: "1.15rem",
    color: "#4A6476"
  }
});



class Header extends React.Component {


  render(){
    const {classes } = this.props;

    return (
    <>
      <Hidden mdDown>
        <div className={classes.root}>
          <AppBar className={classes.appBar} position="fixed">
            <Toolbar>
              <div>
                <Link className={classes.link} activeClassName={classes.active} to="/">
                <Typography className={classes.title} variant="h6" color="inherit" noWrap>
                  Kenneth E. Kaiser
                </Typography>
                <Typography className={classes.title} variant="subtitle2" color="inherit" noWrap>
                  Attorney at Law
                </Typography>
                </Link>
              </div>
              <div className={classes.grow} />
              <Typography className={classes.title} variant="h6" noWrap>
                <Link className={classes.link} activeClassName={classes.active} to="/about">About</Link>
              </Typography>
              <div className={classes.grow} />
              <Typography className={classes.title} variant="h6" color="inherit" noWrap>
                <Link className={classes.link} activeClassName={classes.active} to="/real-estate">Real Estate</Link>
              </Typography>
              <div className={classes.grow} />
              <Typography className={classes.title} variant="h6" color="inherit" noWrap>
                <Link className={classes.link} activeClassName={classes.active} to="/family-law">Family Law</Link>
              </Typography>
              <div className={classes.grow} />
              <Typography className={classes.title} variant="h6" color="inherit" noWrap>
                <Link className={classes.link} activeClassName={classes.active} to="/bankruptcy">Bankruptcy</Link>
              </Typography>
              <div className={classes.grow} />
              <Typography className={classes.title} variant="h6" color="inherit" noWrap>
                <Link className={classes.link} activeClassName={classes.active} to="/general-practice">General Practice</Link>
              </Typography>
              <div className={classes.grow} />
              <Button className={classes.contact} variant="contained" color="default" component={Link} to="/contact">Contact Us</Button>
            </Toolbar>
          </AppBar>
        </div>
      </Hidden>
      <Hidden lgUp>
      <Drawer/>
      </Hidden>
    </>
  );
  }

}


export default withStyles(styles)(Header);
