import React from "react"
import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Layout from '../components/layout';
import Footer from '../components/footer';
import SEO from '../components/seo';

const styles = theme => ({
  gridContainer: {
    width: "100%",
    marginTop: "56px",
    minHeight: "500px",
    height: "100%",
    display: "flex",
    direction: 'column',
    justifyContent: "center",
    alignItems: "center",
  },
  image: {
    marginTop: 50,
    width: "200px",
    height: "200px",
    borderRadius: 100,
  },
  gridContainerSecond: {
    width: "100%",
    height: "auto",
    display: "flex",
    direction: 'column',
    justifyContent: "center",
    alignContent: "center",
    alignItems: "center",
    background: "linear-gradient(to bottom, #4A6476, #000 )"
  },
  contentContainer: {
    display: "flex",
    maxWidth: "1200px",
    height: "auto",
    direction: 'row',
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: "30px",
    marginBottom: "30px"
  },
  item: {
    display: "flex",
    justifyContent: "center",
    alignContent: "center",
    padding: "10px"
  },
  education: {
    display: "flex",
    direction:"column",
    justifyContent: "center",
    alignContent: "center",
    alignItems: "flex-start",
    [theme.breakpoints.down('sm')]: {
      marginBottom: "20px",
    },
  },

  bio: {
    color: "#fff",
    textAlign: "justify",
    //width: "100%"
  },
  bio2: {
    color: "#fff",
    [theme.breakpoints.down('sm')]: {
      textAlign: "center",
    },
    //textAlign: "center",
    //width: "100%"
  }


});



class About extends React.Component {

  renderEducation(){
    const {classes, data } = this.props;
    let education = data.allContentfulAbout.edges[0].node.education;
    return education.map((school, index)=>{
      return (
        <Grid key={school} item xs={12}>
          <Typography variant = "subheading" className = {classes.bio2}>{school}</Typography>
        </Grid >
      );
    })
  }

  renderAdmitted(){
    const {classes, data } = this.props;
    let practices = data.allContentfulAbout.edges[0].node.admittedToPractice;
    return practices.map((practice, index)=>{
      return (
        <Grid key={practice} item xs={12}>
          <Typography variant = "subheading" className = {classes.bio2}>{practice}</Typography>
        </Grid >
      );
    })
  }

  render(){
    const {classes, data } = this.props;
    let image  = data.allContentfulAbout.edges[0].node.image.file.url

    return (
    <Layout>
      <SEO pagePath ="/about/"  pageTitle = "About" keywords = "Attorney, CPA, Cook County, Lake County, McHenry County, Kane County and DuPage County, Family Law, Real Estate, Bankruptcy, Business Litigation and General Practice " pageDescription = "Ken maintains the highest and strictest standards when it comes to his clients and the law. He has a solid reputation for being responsive, dedicated, and thorough which stems from an unyielding work ethic and thirst for knowledge." image = {data.shareImage.childImageSharp.resize.src} imageHeight="400" imageWidth="400"/>
      <Grid className = {classes.gridContainer} container  >
        <Grid className = {classes.gridContainerSecond} container>
          <Grid className = {classes.contentContainer} spacing={24} container>
            <Grid className={classes.item} item xs={10}>
            <Typography variant = "h6" className = {classes.bio}>
              <img alt="ken kaiser" src = {`${image}?w=500&q=50`} className={classes.image} />
            </Typography>
            </Grid>
            <Grid className={classes.item} item xs={10}>
            <Typography variant = "h6" className = {classes.bio}dangerouslySetInnerHTML={{
                __html: data.allContentfulAbout.edges[0].node.bio.childMarkdownRemark.html,
              }}/>
            </Grid>

            <Grid className={classes.item} item container sm={8} md={8}>
              <Grid className={classes.education} item  container sm={12} md={2}>
                <Typography variant = "subheading" className = {classes.bio2}>Admitted to Practice: </Typography>
              </Grid>
              <Grid className={classes.education} item container sm={12} md={4} >
                {this.renderAdmitted()}
              </Grid>
              <Grid className={classes.education} item container sm={12} md={2}>
                <Typography variant = "subheading" className = {classes.bio2}>Education: </Typography>
              </Grid>
              <Grid className={classes.education} item container sm={12} md={4}>
                {this.renderEducation()}
              </Grid>
            </Grid >
          </Grid>
        </Grid>
        <Footer/>
      </Grid>
    </Layout>
  );
  }

}


export default withStyles(styles)(About);

export const pageQuery = graphql`
  query {
    allContentfulAbout {
      edges {
        node {
          title
          image {
            file {
              url
            }
          }
          bio {
            childMarkdownRemark {
              html
            }
          }
          education
          admittedToPractice
        }

      }
    }
    shareImage: file(relativePath: {eq: "Kaiser.jpg"}) {
      childImageSharp {
        resize(width: 400, height: 400) {
          src
        }
      }
    }
  }
`
